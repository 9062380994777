import React from 'react'


import BreadCrumb from '../components/breadCrumb/index'
import Layout from '../components/layout'
import SEO from '../components/seo'
import AllProjects from '../components/projectsList/fullProjectList'
import ThoughtsOn from '../components/thoughtsOn/index'


const crumbs = [
  {
    depth: '1',
    url: '/',
    title: 'Home',
    styles: '',
  },
]

const AboutPage = () => {
  return (
    <Layout>
      <SEO
        title="Projects"
      />
      <BreadCrumb crumbs={crumbs} />
      <section className="flex flex-col md:flex-row items-center">
        <section id="about-summary" className="md:w-2/3 md:mr-8 mb-3">
          <h2 className="text-4xl font-bold mb-3">Projects</h2>
          <p className="text-2xl mb-3">
            Here's a collection of projects I've worked on.
          </p>
        </section>
      </section>
      <AllProjects />
      <ThoughtsOn />
    </Layout>
  )
}

export default AboutPage
