import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { FaChevronRight } from 'react-icons/fa'


const ThoughtsOn = () => {
  const thoughts = useStaticQuery(graphql`
    query {
      allContentfulTypeOfThought {
        edges {
          node {
            title
            id
            image {
              description
              fluid {
                src
              }
            }
          }
        }
      }
    }
  `)
  const thoughtTile = thoughts.allContentfulTypeOfThought.edges.map(thought => {
    return (
      <Link to="/thoughts" className="px-2 w-3/6 mb-3 md:w-1/6 md:mb-0" key={thought.node.id}>
        <div className="overflow-hidden justify-center hover:underline">
          <img src={thought.node.image.fluid.src} alt={thought.node.image.description} />
          <div className="px-6 py-4">
            <h4 className="text-center font-bold mb-1">{thought.node.title}</h4>
          </div>
        </div>
      </Link>
    )
  })

  return (
    <section id="thoughts-on" className="mb-20 text-center">
      <h3 className="text-3xl font-bold">My thoughts</h3>
      <p className="text-xl mb-8">A collection of articles I've written on various topics</p>
      <div className="flex flex-wrap -mx-2">{thoughtTile}</div>
      <Link className="underline font-semibold hover:font-bold" to="/thoughts">
        <button className="hover:bg-teal-600 bg-gray-900 text-white font-bold py-2 px-4 rounded-full mt-3 justify-center">
          See all thoughts <FaChevronRight className="inline" />
        </button>
      </Link>
    </section>
  )
}

export default ThoughtsOn
