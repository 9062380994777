import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'


const AllProjects = () => {
  const projects = useStaticQuery(graphql`
    query {
      allContentfulProject {
        edges {
          node {
            title
            id
            slug
            leadText
            articleImage {
              description
              fluid {
                src
              }
            }
          }
        }
      }
    }
  `)
  const projectTile = projects.allContentfulProject.edges.map(project => {
    return (

      <div className="w-full md:w-1/3 px-5 mb-4" key={project.node.id}>
        <Link to={`/projects/${project.node.slug}`}>
          <div className="overflow-hidden hover:shadow-lg">
            <img
              className="h-auto"
              src={project.node.articleImage.fluid.src}
              alt={project.node.articleImage.description}
            />
            <div className="px-6 py-4 bg-gray-100">
              <h4 className="font-bold text-xl mb-2 leading-tight">{project.node.title}</h4>
              <p className="text-gray-700 text-base">{project.node.leadText}</p>
            </div>
          </div>
        </Link>
      </div >

    )
  })

  return (
    <section id="latest-projects" className="mb-8">
      <div className="flex flex-wrap -mx-5 mt-3">{projectTile}</div>
    </section>
  )
}

export default AllProjects
